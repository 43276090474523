/* eslint max-len: 0 */ // disable linter for too long lines in this file
import React from 'react'

export const Impressum = () => (
  <section className="section">
    <div className="container">
      <div className="content">
        <h1>Impressum</h1>
        <br />
        <dl>
          <dt>Vereinsadresse:</dt>
          <dd>
        Open Knowledge Foundation Deutschland e.V.<br />
        Singerstr. 109<br />
        10179 Berlin<br />
        Deutschland<br />
            <sub>
              <a href="http://www.openstreetmap.org/way/29386807">OpenStreetMap</a> - <a href="https://goo.gl/maps/aHBAbaCA35C2">Google Maps</a>
            </sub><br />
          </dd>
        </dl>
        <dl>
          <dt>Vereinsregister Nr:</dt>
          <dd>VR 30468 B</dd>
          <dt>Umsatzsteuernummer:</dt>
          <dd>DE278022128</dd>
        </dl>
        <dl>
          <dt>E-Mail:</dt>
          <dd><a href="mailto:info@okfn.de">info@okfn.de</a> (<a href="https://pgp.mit.edu/pks/lookup?op=get&amp;search=0xFE54F46F21DDAED1">OpenPGP</a>)</dd>
          <dt>Telefon:</dt>
          <dd><a href="tel:+49 30 57703666 2">+49 30 57703666 0</a></dd>
          <dt>Fax:</dt>
          <dd><a href="fax:+49 30 57703666 9">+49 30 57703666 9</a></dd>
        </dl>
        <dl>
          <dt>Weitere Kanäle:</dt>
          <dd>
            <a href="https://www.twitter.com/okfde">Twitter</a><br />
            <a href="https://www.facebook.com/okfde/">Facebook</a><br />
            <a href="https://www.github.com/okfde">Github</a><br />
            <a href="https://www.flickr.com/photos/okfde">Flickr</a><br />
            <a href="https://vimeo.com/okfn">Vimeo</a><br />
            <a href="https://www.youtube.com/channel/UCUD8EFjXzRN-wFUbVTYlDAw">Youtube</a><br />
            <a href="http://www.meetup.com/de/Open-Knowledge-Foundation-Deutschland-e-V/">Meetup</a><br />
            <a href="https://www.betterplace.org/de/organisations/okfde/">Betterplace</a><br />
          </dd>
        </dl>
        <dl>
          <dt>Webseitendesign:</dt>
          <dd><a href="http://nadinestammen.de/">Nadine Stammen</a>, Felix Becker,
            <a href="http://stefanwehrmeyer.com/">Stefan Wehrmeyer</a>, Andrej Sandorf
          </dd>
        </dl>
        <dl>
          <dt>Technik:</dt>
          <dd>
        Für das Testen von Browserkompatibilität wird diese Seite von
            <a href="https://www.browserstack.com/">BrowserStack</a> unterstützt.
            <br />
        HTML erzeugt mit <a href="https://jekyllrb.com/">Jekyll</a> und DataLove.
          </dd>
        </dl>
        <hr />
        <h3>Haftungsausschluss</h3>
        <p>Inhaltlich verantwortlich gemäß § 55 Abs. 2 RStV ist der jeweilige Autor eines Textes.</p>
        <h3>Haftung für Inhalte</h3>
        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
      Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
      Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis 10 TMG
      sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
      Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
      allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
      ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
      Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <h3>Haftung für Links</h3>
        <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
      Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
      jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
      Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
      Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
      Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <h3>Urheberrecht</h3>
        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
      unterliegen dem deutschen Urheberrecht. Sie sind mit einer Creative Commons Lizenz
      urheberrechtlich geschützt. Sofern nicht anders angegeben, stehen die
      Inhalte dieser Seite unter der Creative Commons Namensnennung 3.0 DE Lizenz.
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
      werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als
      solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
      bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
      entfernen.
        </p>
        <h2>Datenschutzerklärung</h2>
        <h3>I. Datenerhebung und Datenverarbeitung</h3>
        <p>Die Sicherheit Ihrer Daten hat bei uns höchste Priorität. Wir ergreifen jede nur mögliche Maßnahme,
      um Ihre persönlichen Daten vor unbefugtem Zugriff zu schützen. Sämtliche Anforderungen des Bundesdatenschutzgesetzes (BDSG),
      Telemediengesetzes (TMG) sowie alle weiteren datenschutzrechtlichen Vorschriften werden von uns eingehalten.
      Sie können unsere Seite besuchen, ohne Angaben zu Ihrer Person zu machen. Wir speichern dann lediglich Zugriffsdaten ohne
      Personenbezug. Dabei handelt es sich z.B. um Daten
        </p>
        <ul>
          <li>der Rechneradresse (IP-Adresse) in gekürzter Form,</li>
          <li>der Seite, von der aus die Datei angefordert wurde,</li>
          <li>dem Namen der übertragenen Datei,</li>
          <li>dem Datum und der Uhrzeit der Abfrage,</li>
          <li>der übertragenen Datenmenge,</li>
          <li>dem Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.) und
        einer Beschreibung des Typs des verwendeten Webbrowsers.
          </li>
        </ul>
        <p>Diese Daten werden ausschließlich zur Verbesserung unseres Angebotes ausgewertet und erlauben keine Rückschlüsse auf Ihre Person.</p>
        <h3>II. Erhebung und Speicherung personenbezogener Daten</h3>
        <p>Personenbezogene Daten wie Name, Username und zusätzliche Metadaten wie z. B. Anschrift,
      Telefonnummer und Email-Adresse (Bestandsdaten) werden erhoben, wenn und soweit dies im Rahmen der Begründung und Abwicklung eines
      Vertragsverhältnisses sowie für dessen inhaltliche Ausgestaltung oder Änderung erforderlich ist.
      Diese Daten werden in der Regel in elektronischen Anmeldeformularen erhoben. Mit vollständiger Abwicklung des Vertrages werden Ihre
      Daten für die weitere Verwendung gesperrt und nach Ablauf der steuer- und handelsrechtlichen
      Vorschriften gelöscht, sofern Sie nicht ausdrücklich in die weitere Nutzung Ihrer Daten eingewilligt haben.
        </p>
        <h3>III. Übermittlung von Daten an Dritte</h3>
        <p>Eine Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt grundsätzlich nur,
      sofern dies zur Erbringung unserer Dienstleistung oder zur Vertragsdurchführung notwendig ist und Sie ausdrücklich eingewilligt haben.
        </p>
        <h3>IV. Verwendung von Cookies</h3>

        <h3>V. Newsletter</h3>
        <p>Bei Anmeldung zum E-Mail-Newsletter wird Ihre E-Mailadresse mit Ihrer Einwilligung
      für eigene Zwecke genutzt, bis Sie sich vom Newsletter abmelden. Eine Abmeldung ist jederzeit über den dafür vorgesehenen Link im Newsletter oder eine
      entsprechende Mitteilung an uns möglich.
        </p>
        <h3>VI. Abrufbarkeit der Datenschutzerklärung</h3>
        <p>Sie können die Datenschutzerklärung jederzeit von jeder Seite dieses Internetangebots
      unter dem Link „Impressum“ abrufen. Bitte nehmen Sie zur Kenntnis, dass Änderungen des Angebots, gesetzliche und rechtliche Neuerungen sowie ggf.
      nachträglich erkannte Regelungslücken Änderungen dieser Datenschutzerklärung erforderlich werden lassen können.
        </p>
        <h3>VII. Fragen und Auskunftsrecht</h3>
        <p>Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit und kostenlos Rede und Antwort
      bezüglich der Verarbeitung Ihrer personenbezogenen Daten stehen. Auch haben Sie nach dem Bundesdatenschutzgesetz ein Recht auf
      unentgeltliche Auskunft über Ihre gespeicherten Daten sowie ggf. ein Recht auf Berichtigung,
      Sperrung oder Löschung dieser Daten. Sie können außerdem jederzeit die uns erteilte Einwilligung zur Datenerhebung und Verwendung ohne
      Angaben von Gründen widerrufen. Wenn Sie Fragen haben, die Ihnen diese Datenschutzerklärung
      nicht beantworten konnte oder wenn Sie zu einem Punkt vertiefte Informationen wünschen,
      wenden Sie sich bitte jederzeit an <a href="mailto:info@okfn.de">info@okfn.de</a>.
        </p>
        <h3>VIII. Sicherheitshinweis</h3>
        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
      Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
        </p>
      </div>
    </div>
  </section>
)
