import React, { Component } from 'react'
import { RouterRoot } from './Router/RouterRoot'

export class App extends Component {
  render() {
    return (
      <RouterRoot />
    )
  }
}
